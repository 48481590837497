/**
 * @ngdoc factory
 * @name commonModule.AgentService
 * @description This is a factory used for agent related REST calls
 * @requires $log mainly used for logging. Used only $log.debug and could be enabled/disabled based on need.
 * @requires $http for POST request
 * @requires $resource for other REST calls.
 * @requires $sessionStorage to pass the session storage data
 */
(function () {
  'use strict';

  angular.module('commonModule').factory('AgentService', [
    '$http',
    '$sessionStorage',
    '$resource',
    '$log',
    function ($http, $sessionStorage, $resource, $log) {
      return {
        getCreateGMDropDown: function () {
          return $resource(
            'v1/generalmeetings/getGeneralMeetingDropdownList',
            {},
            {
              get: {
                method: 'GET',
                isArray: false,
              },
              update: {
                method: 'PUT',
              },
            }
          );
        },
        getExtensionRequests: function (pageNo) {
          return $resource(
            'v1/extensionrequest/' +
              $sessionStorage.groupId +
              '/' +
              'AG' +
              '/' +
              pageNo +
              '/500/GM',
            {},
            {
              query: {
                method: 'GET',
                isArray: true,
              },
            }
          );
        },
        getAllExtensionRequestForIssuerGM: function (
          pageNo,
          meetingId,
          userGroupId
        ) {
          return $resource(
            'v1/extensionrequest/' +
              userGroupId +
              '/' +
              $sessionStorage.usertype +
              '/' +
              pageNo +
              '/500' +
              '/' +
              meetingId +
              '/extnReqsForGM',
            {},
            {
              query: {
                method: 'GET',
                isArray: true,
              },
            }
          );
        },
        getAllExtensionRequestForGM: function (pageNo, meetingId) {
          return $resource(
            'v1/extensionrequest/' +
              $sessionStorage.groupId +
              '/' +
              $sessionStorage.usertype +
              '/' +
              pageNo +
              '/500' +
              '/' +
              meetingId +
              '/extnReqsForGM',
            {},
            {
              query: {
                method: 'GET',
                isArray: true,
              },
            }
          );
        },

        cancelExtensionRequest: function (extReqId, authtoken, callback) {
          var response = $resource(
            'v1/extensionrequest/' +
              extReqId +
              '/' +
              $sessionStorage.userId +
              '/cancelExtnReq',
            {},
            {
              save: {
                method: 'PUT',
                headers: {
                  Accept: 'application/json',
                  'Content-Type': 'application/json',
                  'X-Internal-Auth-Token': authtoken,
                },
              },
            }
          );
          var _resource = new response();
          _resource.$save(callback);
        },

        approveRejectForGM: function (data, authtoken, callback) {
          $http({
            headers: {
              Accept: 'application/json',
              'Content-Type': 'application/json',
              'X-Internal-Auth-Token': authtoken,
            },
            url: 'v1/extensionrequest/approveRejectER',
            method: 'PUT',
            data: data,
          }).then(callback);
        },
        createGeneralMeeting: function (data, authtoken) {
          $log.debug(data);
          return $http({
            headers: {
              Accept: 'application/json',
              'Content-Type': 'application/json',
              'X-Internal-Auth-Token': authtoken,
            },
            url: 'v1/generalmeetings/create',
            method: 'POST',
            data: data,
          });
        },
        uploadFileToUrl: function (file, uploadUrl) {
          var fd = new FormData();
          fd.append('file', file);
          $http
            .post(uploadUrl, fd, {
              transformRequest: angular.identity,
              headers: {
                'Content-Type': undefined,
              },
            })
            .then(
              function () {
                $log.debug('success');
              },
              function (error) {
                $log.debug('error', error);
                throw Error(error);
              }
            );
        },
        /* SFCBP-4879
         * This http request does not adhere to the REST API standard.
         * Preferably JSON should be used as exchange format with the file
         * encoded in Base64. Due to tight coupling in the backend code
         * it was decided to keep FormData as exchange format and
         * add the inviteSharePlanEmailVO transfer object to the FormData object
         * as a JSON String.
         */
        uploadSharePlan: function (file, inviteShareplanEmailVO) {
          let fd = new FormData();
          fd.append('fileData', file);
          fd.append(
              'inviteSharePlanEmailText',
              angular.toJson(inviteShareplanEmailVO)
          );
          return $http({
            method: 'POST',
            url: 'v1/sharePlan/bulkupload',
            headers: {
              'Content-Type': undefined,
            },
            data: fd,
          });
        },
        saveInviteSharePlan: function (data) {
          return $http({
            headers: {
              'Content-Type': undefined,
            },
            url: 'v1/sharePlan/bulkupload',
            method: 'POST',
            data: data,
          });
        },
        sendInviteSharePlanPreview: function (data) {
          return $http({
            headers: {
              Accept: 'application/json',
              'Content-Type': 'application/json',
            },
            url: 'v1/sharePlan/preview',
            method: 'POST',
            data: data,
          });
        },
        importDocFiles: function (meetingId) {
          $log.debug();
          return $http({
            headers: {
              Accept: 'application/json',
              'Content-Type': 'application/json',
            },
            url: 'v1/sharePlan/' + meetingId,
            method: 'GET',
          });
        },
        getInviteSPDeatails: function (meetingId, emailLogId) {
          return $http({
            headers: {
              Accept: 'application/json',
              'Content-Type': 'application/json',
            },
            url:
              'v1/reinvite/viewEmail/' +
              $sessionStorage.userId +
              '/' +
              $sessionStorage.usertype +
              '/' +
              meetingId +
              '/' +
              emailLogId,
            method: 'GET',
          });
        },
        reinviteSharePlan: function (data, authtoken) {
          return $http({
            headers: {
              Accept: 'application/json',
              'Content-Type': 'application/json',
              'X-Internal-Auth-Token': authtoken,
            },
            url:
              'v1/reinvite/sendReinvite/' +
              $sessionStorage.userId +
              '/' +
              $sessionStorage.usertype,
            method: 'POST',
            data: data,
          });
        },
      };
    },
  ]);
})();
