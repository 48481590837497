angular
  .module('cbpFilterModule', [])
  /**
   * Converts a timestamp into a date in the format dd MMM yyyy HH:mm in the
   * provided timezone and then concatenates to time zone to it.
   *
   * @param timestamp - the timestamp
   * @param zone - the zone i.e. CET or GMT
   * @param includeZone - true if timezone needs to be concatenated
   */
  .filter('cbpDate', [
    '$filter',
    function ($filter) {
      return function (timestamp, zone, includeZone) {
        let dateTime = $filter('date')(timestamp, 'dd MMM yyyy HH:mm', zone);

        if (includeZone === true) {
          dateTime += ' ' + zone;
        }

        return dateTime;
      };
    },
  ])

  /**
   * Converts an iso formatted datetime string into a date in the format dd MMM yyyy HH:mm
   * The result is not converted to a timezoned datetime.
   *
   * @param isoDate - the iso formatted input datetime string to be reformatted
   */
  .filter('cbpIsoDate', [
    '$filter',
    function ($filter) {
      return function (isoDate) {
        var m = require('moment');

        return m.parseZone(isoDate, m.ISO_8601).format('DD MMM YYYY HH:mm');
      };
    },
  ])
  /**
   * Calculates the som of a property inside a list.
   *
   * @param list - the list contain the property to sum up
   * @param the - property to be summed up
   */
  .filter('cbpSum', [
    function () {
      return function (list, property) {
        let total = 0;
        list.forEach(function (element) {
          total += element[property];
        });
        return total;
      };
    },
  ])

  /**
   * decode &lt; and &gl; to html tags respectively < and >
   */
  .filter('htmlDecode', [
    '$sanitize',
    function ($sanitize) {
      function decode(value) {
        let div = document.createElement('div');
        div.innerHTML = value;
        let textValue = div.textContent;
        div.remove();
        return textValue;
      }

      function encode(value) {
        let div = document.createElement('div');
        div.textContent = value;
        let textValue = div.innerHTML
          .replace(/\"/g, '&#34;')
          .replace(/\'/g, '&#39;');
        div.remove();
        return textValue;
      }

      function isNotDecoded(text) {
        let decodedValue = decode(text);
        let encodedValue = encode(decodedValue);

        // Copy pasted text contains \n\r, these can be ignored
        return (
          text.replace(/[\r\n]/g, '') === encodedValue.replace(/[\r\n]/g, '')
        );
      }

      return function (text) {
        if (text) {
          if (isNotDecoded(text)) {
            return decode(text);
          } else {
            return text;
          }
        } else {
          return '';
        }
      };
    },
  ])

  .filter('json', [
    function () {
      return function (obj, spaces) {
        if (spaces) {
          return JSON.stringify(obj, null, spaces);
        } else {
          return JSON.stringify(obj);
        }
      };
    },
  ])

  .filter('dwtClaimType',
    ['$filter', function ($filter) {
      return function (expr) {
        switch (expr) {
          case 'PE': return $filter('translate')('label_claimWht');
          case 'QRF': return $filter('translate')('label_claimRas');
          default: return expr;
        };
      };
    },
  ]);
