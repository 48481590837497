export const HOME_LABELS = {
  EN: 'Home',
  NL: 'Home',
  FR: 'Accueil',
  DE: 'Home',
  ES: 'Home'
};

export const GM_LABELS = {
  EN: 'General Meetings',
  NL: 'Algemene vergaderingen',
  FR: 'Assemblées générales',
  DE: 'Hauptversammlungen',
  ES: 'Juntas Generales'
};

export const DWT_LABELS = {
  EN: 'DWT',
  NL: 'nl_DWT',
  FR: 'fr_DWT',
};

export const MEETING_LABELS = {
  EN: 'Meetings',
  NL: 'Vergaderingen',
  FR: 'Assemblées générales',
  DE: 'Hauptversammlungen',
  ES: 'Junta'
};

export const SHID_LABELS = {
  EN: 'Shareholders ID',
  NL: 'Shareholders ID',
  FR: 'Actionnaires ID',
  DE: 'Aktionärs-ID',
  ES: 'Id accionistas'
};

export const ACCOUNT_LABELS = {
  EN: 'My Account',
  NL: 'Mijn gegevens',
  FR: 'Mon compte',
  DE: 'Meine Angaben',
  ES: 'Mi Cuenta'
};

export function MenuItem(name, state, index) {
  this.name = name;
  this.url = state;
  this.index = index;
}
